<template>
  <v-row>
    <v-col cols="12" lg="6">
      <div
        class="text-subtitle-1 font-weight-bold text-center"
        v-text="$t('audit.old.values')"
      />
    </v-col>
    <v-col cols="12" lg="6">
      <div
        class="text-subtitle-1 font-weight-bold text-center"
        v-text="$t('audit.new.values')"
      />
    </v-col>
    <template v-if="sComponent">
      <v-col cols="12" lg="6">
        <vue-code-highlight language="json5">
          <pre v-text="sOldData" />
        </vue-code-highlight>
        <!--        <component :is="sComponent" :value="obOldData" />-->
      </v-col>
      <v-col cols="12" lg="6">
        <vue-code-highlight language="json5">
          <pre v-text="sNewData" />
        </vue-code-highlight>
        <!--        <component :is="sComponent" :value="obNewData" />-->
      </v-col>
    </template>
  </v-row>
</template>

<script lang="ts">
import { AuditData } from "@planetadeleste/vue-mc-audits";
import { Component, Prop, Vue } from "vue-property-decorator";
import { kebabCase, last, map, replace } from "lodash";
import { component as VueCodeHighlight } from "vue-code-highlight";
import AuditCategoryItem from "@/modules/audits/components/AuditCategoryItem.vue";
import AuditOfferItem from "@/modules/audits/components/AuditOfferItem.vue";
import AuditPriceItem from "@/modules/audits/components/AuditPriceItem.vue";
import "vue-code-highlight/themes/prism-tomorrow.css";

@Component({
  components: {
    AuditCategoryItem,
    AuditOfferItem,
    AuditPriceItem,
    VueCodeHighlight,
  },
})
export default class AuditDataView extends Vue {
  @Prop(Object) readonly item!: AuditData;

  get obOldData() {
    return this.item ? this.setAuditItems(this.item.old_values) : {};
  }

  get obNewData() {
    return this.item ? this.setAuditItems(this.item.new_values) : {};
  }

  get sOldData() {
    return this.item && this.item.old_values
      ? JSON.stringify(this.item.old_values, null, 2)
      : "";
  }

  get sNewData() {
    return this.item && this.item.new_values
      ? JSON.stringify(this.item.new_values, null, 2)
      : "";
  }

  get sComponent() {
    if (!this.item || !this.item.auditable_type) {
      return undefined;
    }

    const sModelName = last(this.item.auditable_type.split("\\")) as string;
    return sModelName ? kebabCase(`Audit${sModelName}Item`) : undefined;
  }

  setAuditItems(obData: Record<string, any>) {
    return map(obData, (sValue: any, sKey: string) => {
      return {
        key: sKey,
        value: sValue,
        label: replace(sKey, "_", "."),
      };
    });
  }
}
</script>
